import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/uninstallexe.module.scss"
import Footerlinkssingle from "@tightrope/footerlinks/footerlinks_single"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Uninstallexe extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.uninstall}>
          <body>
            <section className={Styles.supportPages}>
            <img src="/img/carbonate-logo.png" id = {Styles.logo}/>
            <h1>Uninstall</h1>

            <div className={Styles.container}>

              <div className={Styles.innerContainer}>
            <p>Follow the directions below to uninstall software from your operating system or browser.</p>



            <ul className={Styles.uninstallList}>
              <li><AnchorLink to="/uninstall#ELEVEN" title="Windows 11" /></li>
              <li><AnchorLink to="/uninstall#TEN" title="Windows 10" /></li>
              <li><AnchorLink to="/uninstall#EIGHT" title="Windows 8" /></li>
              <li><AnchorLink to="/uninstall#SEVEN" title="Windows 7" /></li>
              <li><AnchorLink to="/uninstall#VISTA" title="Windows Vista" /></li>
              <li><AnchorLink to="/uninstall#XP" title="Windows XP" /></li>
            </ul>
            </div></div>



            <h2 id="ELEVEN">Windows 11</h2>



            <p>Uninstall from the Control Panel</p>



            <ol><li>In the Start menu search box, type <strong>remove programs</strong> and select the “Add or remove programs” Control panel from the matches. The “Apps &gt; Apps &amp; features” Settings should open.</li><li>Scroll to “Carbonate Browser” in the list of apps.</li><li>Select “Uninstall” from the menu (represented by a stack of three dots) for Carbonate Browser located to the right of its file size.</li></ol>



            <img width="800" height="322" src="/img/windows11-uninstall.jpg" alt="Uninstall Carbonate Browser Windows 11" sizes="(max-width: 800px) 100%, 800px"/>



            <h2 id="TEN">Windows 10</h2>



            <p>Uninstall from the Control Panel</p>



            <ol><li>In the Start menu search box, type <strong>remove programs</strong> and select the “Add or remove programs” Control panel from the matches. The “Apps &amp; features” Settings should open.</li><li>Scroll to “Carbonate Browser” in the list of apps and select it.</li><li>Select the <strong>Uninstall</strong> button.</li></ol>



            <img loading="lazy" width="800" height="322" src="/img/windows10-uninstall.jpg" sizes="(max-width: 800px) 100%, 800px"/>



            <h2 id="EIGHT">Windows 8</h2>



            <ol><li>Swipe in from the right edge of the screen using your mouse (or finger for touchscreen displays), and then click <strong>Search</strong>.</li><li>Enter <strong>Uninstall</strong> in the search box and then click the <strong>Settings</strong> category below the search box.</li><li>Click <strong>Uninstall a Program</strong> from list on the left side of the screen.</li><li>From the <strong>Programs and Features</strong> dialog box that opens, select the program you wish to uninstall from your computer.</li><li>Click <strong>Uninstall</strong>.</li><li>Restart your computer.</li></ol>



            <h2 id="SEVEN">Windows 7</h2>



            <ol><li>From the <strong>Start Menu</strong> find and select <strong>Control Panel</strong></li><li>Select <strong>Programs and Features</strong> icon</li><li>Select the program you wish to uninstall</li><li>Click on the <strong>Uninstall</strong> button (or right-click the program and select <strong>Uninstall</strong> option)</li><li>Follow the prompts to finish the uninstall process</li></ol>



            <h2 id="VISTA">Windows Vista</h2>



            <ol><li>From the <strong>Start Menu</strong> find and select <strong>Control Panel</strong></li><li>Select <strong>Programs</strong> icon and click the <strong>Uninstall A Program</strong> link</li><li>Select the program you wish to uninstall</li><li>Click the <strong>Uninstall/Change button</strong> (or right-click the program and select <strong>Uninstall</strong> option)</li><li>Follow the prompts to finish the uninstall process</li></ol>



            <h2 id="XP">Windows XP</h2>



            <ol><li>From the <strong>Start Menu</strong> find and select <strong>Control Panel</strong></li><li>Select the <strong>Add or Remove Programs</strong> icon</li><li>Select the program you wish to uninstall</li><li>Click the <strong>Add/Remove</strong> button</li><li>Follow the prompts to finish the uninstall process</li></ol>



            <p></p>
            </section>

              <div class={Styles.footerLinks}>
     <ul>
       <li>Tightrope Interactive </li>
       <li><a href="/terms-of-service/" target="_blank">Terms</a></li>
       <li><a href="/privacy-policy/" target="_blank">Privacy</a></li>
       <li><a href="/contact-us/" target="_blank">Contact</a></li>
       <li><a href="/uninstall/" target="_blank">Uninstall</a></li>
       <li>All trademarks are property of their respective owners</li>
       <li><a href="/privacy-policy/#cali-consumer/">Do Not Sell My Personal Information</a></li>
     </ul>
   </div>


          </body>
        </div>
    )
  }
}
export default Uninstallexe
