import React from "react"
import Helmet from "react-helmet"
import UninstallExe from "src/components/uninstallExe"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UninstallPage() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Uninstall - Carbonate Browser</title></Helmet>
    <UninstallExe></UninstallExe>
    </HomepageLayout>
  )
}
